import { useEffect, useRef } from 'react';
import { useScript } from '../../hooks/use-script';

export const CoupangBannerAd = ({ onClick }) => {
  const hasCoupangAd = useRef(false);

  useEffect(() => {
    window.focus();
    const onClickCoupangAd = () => {
      setTimeout(() => {
        if (document.activeElement.tagName === 'IFRAME') {
          onClick();
        }
      }, 1000);
    };

    window.addEventListener('blur', onClickCoupangAd);

    return () => window.removeEventListener('blur', onClickCoupangAd);
  }, []);

  useEffect(() => {
    const iframe = document.getElementById('iframe-test');
    var iframeDoc = iframe.contentWindow || iframe.contentDocument;

    const html = `
    <!doctype html>
<html lang="en">
  <head>
  </head>
  <body>
  <script src="https://ads-partners.coupang.com/g.js"></script>
    <script>
      new PartnersCoupang.G({"id":730018,"template":"carousel","trackingCode":"AF7829779","subId":"CPSconpickk","width":"300","height":"300","tsource":""});
      window.onload(console.log('inner load'))
    </script>
    <div id="root"></div>
  </body>
</html>`;

    iframeDoc.document.open();
    iframeDoc.document.write(html);
    iframeDoc.document.close();

    // iframeDoc.addEventListener('mouseover', (e) => console.log(e.type));
    // iframeDoc.addEventListener('mouseout', (e) => console.log(e.type));

    // iframeDoc.addEventListener('touchstart', (e) => console.log(e.type)); //mobile
    // iframeDoc.addEventListener('touchend', (e) => console.log(e.type)); //mobile
    iframeDoc.addEventListener('load', (e) => {
      iframeDoc.addEventListener('focus', (e) => console.log(e.type)); //mobile
      iframeDoc.addEventListener('click', (e) => console.log(e.type));
      iframeDoc.addEventListener('touchstart', (e) => console.log(e.type)); //mobile
      iframeDoc.addEventListener('touchend', (e) => console.log(e.type)); //mobile
      console.log(e.type);
    }); //mobile

    var mobileLong = 0;
    function touchStart(evt) {
      mobileLong = Date.now(); //터치한 시간 얻어옵니다
    }
    function touchEnd(evt) {
      var result = Date.now() - mobileLong;
      if (Number(result) > 150) {
        //롱 터치 발생
        console.log('');
        console.log('[two_container] : [touchend] : [LongTouch]');
        console.log('[start] : ' + mobileLong);
        console.log('[end] : ' + Date.now());
        console.log('[data] : ' + Number(result));
        console.log('');
        alert('LongTouch');
      } else {
        console.log('');
        console.log('[two_container] : [touchend] : [result]');
        console.log('[data] : ' + Number(result));
        console.log('');
      }
    }
  }, []);

  return (
    <div>
      <iframe id="iframe-test" title="iframe-test" className="w-[310px] h-[300px]" />
    </div>
  );
};
