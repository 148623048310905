import './coupang-ad.css';
import { useEffect, useState } from 'react';
import CoupangLogo from '../../assets/coupang-logo.png';
import { CoupangBannerAd } from '../coupang-banner-ad';
import closeButton from '../../assets/close-button-circle.png';
import { useScript } from '../../hooks/use-script';

export const CoupangAd = ({ onClose, onClickVisitButton }) => {
  const [loading, error] = useScript('https://ads-partners.coupang.com/g.js');

  const hasScript = !loading && !error;

  useEffect(() => {
    // Applying on mount
    document.body.style.overflow = 'hidden';
    // Applying on unmount
    return () => {
      document.body.style.overflow = 'visible';
    };
  });

  // eslint-disable-next-line no-undef
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (!hasScript) return;
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, hasScript]);

  return (
    <div
      id="coupang-ad-container"
      style={{
        height: `${window.innerHeight}px`,
      }}
    >
      <div className="coupang-ad-background"></div>
      <div className="coupang-ad-popup">
        <div className="coupang-ad-popup-content" role="presentation">
          <img src={CoupangLogo} alt="coupang-logo" />
          <div className="coupang-popup-ad-text-section">
            <span>지금 초특가 가득한 쿠팡 방문하면,</span>
            <span>웹툰 보고 리워드 적립까지</span>
          </div>
          {<CoupangBannerAd onClick={onClickVisitButton} />}
          {counter ? (
            <div className="text-white text-[12px] right-[5px] top-[5px] absolute w-[15px] h-[15px] flex justify-center items-center rounded-full bg-black">
              {counter}
            </div>
          ) : (
            <img
              role="presentation"
              onClick={onClose}
              className="absolute top-[5px] right-[5px]"
              src={closeButton}
              alt="close-btn"
            />
          )}
        </div>
      </div>
    </div>
  );
};
