import { useEffect, useState } from 'react';

const useIframeFocus = () => {
  console.log('?');
  // const onClickAd = (iframeId, cbFunc) => {
  //   if (document.activeElement.tagName === 'IFRAME' && document.activeElement.id === iframeId) {
  //     console.log(iframeId, 'iframeId');
  //     window.focus();
  //     cbFunc();
  //   }
  // };

  useEffect(() => {
    window.focus();

    window.document.addEventListener('visibilitychange', function () {
      if (window.document.hidden) {
        console.log('visibility changed');
        window.focus();
      }
    });

    window.addEventListener('focus', () => console.log('window focus'));
    // window.addEventListener('blur', onClickCoupangAd);

    return () => {
      // window.removeEventListener('blur', onClickCoupangAd);
      window.removeEventListener('focus', () => console.log('window focus'));
    };
  }, []);
};

export default function TestPage() {
  useIframeFocus();

  return (
    <div style={{ maxWidth: '500px', height: '100%', margin: 'auto', overflow: 'scroll' }}>
      <div className="flex flex-col overflow-scroll">
        <EmptyBox desc="window blur - iframe1" />
        <IframeV2 iframeId="iframe-1" />
        <EmptyBox desc="window blur - iframe2" />
        <IframeV2 iframeId="iframe-2" />
        <EmptyBox desc="window blur - iframe3" />
        <IframeV2 iframeId="iframe-3" />
        <EmptyBox desc="window blur - iframe4" />
        <IframeV2 iframeId="iframe-4" />
      </div>
    </div>
  );
}

const EmptyBox = ({ desc }) => {
  return (
    <div className="w-full h-[150px] bg-gray-500 flex justify-center items-center text-white">
      {desc}
    </div>
  );
};

const IframeV2 = ({ iframeId }) => {
  const onClickAd = (iframeId, cbFunc) => {
    console.log('onclickAd', iframeId);
    if (document.activeElement.tagName === 'IFRAME' && document.activeElement.id === iframeId) {
      console.log(iframeId, 'iframeId');
      window.focus();
      cbFunc();
    }
  };

  useEffect(() => {
    window.addEventListener('blur', () =>
      onClickAd(iframeId, () => alert(`${iframeId} is clicked!`))
    );
    // window.focus();
    // // const timer = setInterval(() => {
    // //   window.focus();
    // // }, 1000);

    // const onClickCoupangAd = () => {
    //   console.log(document.activeElement, '?');
    //   if (document.activeElement.tagName === 'IFRAME') {
    //     const clickediframeId = document.activeElement.id;
    //     // console.log(clickediframeId);
    //     alert(`${clickediframeId}(window) is clicked!!`);
    //     window.focus();
    //   }
    // };

    // window.onblur = onClickCoupangAd;

    // window.document.onvisibilitychange = () => {
    //   window.focus();
    // };

    const iframe = document.getElementById(iframeId);

    const iframeDoc = iframe.contentWindow || iframe.contentDocument;

    const html = `
      <!doctype html>
  <html lang="en">
    <head>
    <style>
    body {
        margin: 0;
        padding: 0;
    }</style>
    </head>
    <body>
    <script src="https://ads-partners.coupang.com/g.js"></script>
      <script>
        new PartnersCoupang.G({"id":730018,"template":"carousel","trackingCode":"AF7829779","subId":"CPSconpickk","width":"300","height":"300","tsource":""});

      </script>
      <div id="root"></div>
    </body>
  </html>`;

    iframeDoc.document.open();
    iframeDoc.document.write(html);
    iframeDoc.document.close();

    return () => {
      window.removeEventListener('blur', () =>
        onClickAd(iframeId, () => alert(`${iframeId} is clicked!`))
      );
    };
  }, []);

  return <iframe id={iframeId} title={iframeId} className="w-[300px] h-[300px]" />;
};

// const Iframe = ({ iframeId }) => {
//   useEffect(() => {
//     const iframe = document.getElementById(iframeId);

//     const doc = iframe.contentWindow.doc || iframe.contentDocument;

//     const html = `
//       <!doctype html>
//   <html lang="en">
//     <head>
//     <style>
//     body {
//         margin: 0;
//         padding: 0;
//     }</style>
//     </head>
//     <body>

//       <div id="root"></div>
//       <script src="https://ads-partners.coupang.com/g.js"></script>
//       <script>
//         new PartnersCoupang.G({"id":730018,"template":"carousel","trackingCode":"AF7829779","subId":"CPSconpickk","width":"300","height":"300","tsource":""});

//       </script>
//     </body>
//   </html>`;

//     doc.open();
//     doc.write(html);
//     doc.close();

//     // const logevent = function (e) {
//     //   console.log(e.type, e.type);
//     // };

//     // const showAlert = function (e) {
//     //   console.log(e.type);
//     //   return alert(`${iframeId} is clicked!`);
//     // };

//     doc.addEventListener('load', (e) => {
//       console.log(e.type, 'load');
//       doc.addEventListener('mouseover', (e) => console.log(e.type, 'mouse over'));

//       doc.addEventListener('touchstart', (e) => {
//         console.log(e.type, e.type);
//       }); //mobile
//       doc.addEventListener('touchend', function (e) {
//         console.log(e.type);
//         return alert(`${iframeId} is clicked!`);
//       }); //mobile
//       doc.addEventListener('mouseup', (e) => {
//         console.log(e.type, e.type);
//       }); //mobile
//       doc.addEventListener('mousedown', function (e) {
//         console.log(e.type, e.type);
//       }); //mobile
//     });
//     // iframeDoc.addEventListener('load', (e) => {
//     //   console.log(e.type);

//     //   // iframeDoc.addEventListener('focus', ); //mobile
//     //   // iframeDoc.addEventListener('click', logevent, { passive: true });
//     //   iframeDoc.addEventListener('touchstart', logevent, { passive: true }); //mobile
//     //   iframeDoc.addEventListener('touchend', showAlert, { passive: true }); //mobile
//     // }); //mobile
//   }, []);

//   return <iframe id={iframeId} title={iframeId + iframeId} className="w-full h-[300px]" />;
// };
