import { useState } from 'react';
import { WebtoonListPage, WebtoonPage } from './webtoon';
import { NewsListPage } from './news/news-list-page';
import { default as TestPage } from './test-page';

export const ListPage = () => {
  const [currentTab, clickTab] = useState(0);

  const menuArr = [
    { name: '인기 뉴스', content: <NewsListPage /> },
    { name: '무료 웹툰 리스트', content: <WebtoonListPage /> },
    // { name: 'TestPage', content: <TestPage /> },
  ];

  return (
    <div className="flex flex-col py-[30px] m-auto h-[100vh] w-[90%] items-center justify-center max-w-[500px] overflow-hidden">
      <div className="flex justify-between w-full">
        {menuArr.map((el, index) => (
          <li
            role="presentation"
            key={index}
            className={`${
              currentTab === index && 'font-bold'
            } flex w-full font-semi-bold justify-center text-[16px] text-[#212121] p-[10px] first-line:h-[30px] items-center`}
            onClick={() => clickTab(index)}
          >
            {el.name}
          </li>
        ))}
      </div>
      <div className="border-[1px] h-full w-full border-[#EEEEEE]">
        {menuArr[currentTab].content}
      </div>
    </div>
  );
};
